.item {
  position: relative;
  transition: all 0.3s;

  & + .item {
    margin-top: 0.2rem;
  }
  &:hover {
    border-radius: 4px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
    background: #ffffff;
    overflow: hidden;

    .imageWrap {
      .mask {
        padding-top: 100px;
        top: 0;
      }
    }
    .title {
      top: 46px;
    }
  }
  .imageWrap {
    position: relative;
    overflow: hidden;

    :global {
      .ant-image {
        display: block;
        width: 100%;
      }
      .ant-image-img {
        width: 100%;
      }
    }
    .mask {
      position: absolute;
      left: 0;
      top: 100%;
      right: 0;
      padding: 40px 20px 20px;
      width: 100%;
      height: 100%;
      border-radius: 4px 4px 0px 0px;
      background: linear-gradient(180deg, rgba(16, 81, 200, 0.9) 0%, rgba(0, 0, 0, 0.9) 100%);
      transition: all 0.3s;

      .describe {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 1.7;
        font-size: 14px;
        color: #fff;
      }
      button {
        position: absolute;
        left: 50%;
        bottom: 30px;
        transform: translateX(-50%);
        padding: 0 32px;
        height: 32px;
        color: #fff;
        border-radius: 4px;
        border: 1px solid #fff;
        background: transparent;
        transition: all 0.3s;

        &:hover {
          border-color: #2d69d8;
          background: #2d69d8;
        }
      }
    }
  }
  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    font-size: 24px;
    font-weight: 400;
    color: #fff;
    text-align: center;
    transform: translate(-50%, -50%);
    transition: all 0.3s;

    &:after {
      position: absolute;
      bottom: -20px;
      left: 50%;
      content: '';
      width: 40px;
      height: 4px;
      background: #fff;
      transform: translate(-50%, 0);
    }
  }
}

@primary-color: #249BF9;@border-radius-base: 4px;@text-color: #515151;