.casesMain {
  background-color: #fff;
  padding-bottom: 40px;

  .elSl {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .selectBox {
    padding: 32px 0 12px 0;

    .selectRow {
      display: flex;
      // align-items: center;
      margin-bottom: 20px;

      .label {
        width: 80px;
        height: 38px;
        line-height: 38px;
        margin-right: 24px;
        color: #2D69D8;
      }

      .selectCont {
        flex: 1;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .contMain {
          padding: 8px 18px;
          margin-right: 32px;
          border-radius: 19px;
          background-color: transparent;
          cursor: pointer;
          color: #515151;

          &.active {
            background-color: #2D69D8;
            color: #fff;
          }
        }
      }
    }
  }

  .listUl {
    .listLi {
      display: flex;
      padding: 20px;
      margin-bottom: 20px;
      cursor: pointer;

      &:hover {
        background: #FFFFFF;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
      }

      .left {
        margin-right: 40px;
        width: 400px;
        img {
          width: 100%;
          height: 240px;
          border-radius: 4px;
        }
      }

      .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title {
          font-size: 22px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #070707;
          line-height: 30px;
          margin-bottom: 16px;
          max-width: 680px;
        }

        .subscribe {
          height: 113px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #858585;
          line-height: 22px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }

        .bottom {
          overflow: hidden;
          width: 100%;
          .bottomL {
            float: left;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            max-width: 500px;

            .tag {
              height: 20px;
              line-height: 20px;
              border-radius: 10px;
              padding: 0px 12px;
              font-size: 12px;
              margin-right: 12px;
              margin-bottom: 12px;
              max-width: 180px;
            }

            .blueTag {
              background: rgba(45, 105, 216, 0.1);
              color: #2D69D8;
            }

            .orangeTag {
              background: #FEF8F3;
              color: #FF7212;
            }
          }

          .bottomR {
            float: right;
            color: #858585;
            .rMain {
              display: flex;
              align-items: center;
              img {
                width: 14px;
                height: 14px;
                margin-right: 9px;
              }
            }
          }
        }
      }
    }
  }

  .pageBox {
    text-align: right;
    margin-bottom: 40px;
  }
}
@primary-color: #249BF9;@border-radius-base: 4px;@text-color: #515151;