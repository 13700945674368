.tabs {
  :global {
    .ant-tabs-nav {
      &:before {
        opacity: 0;
      }
    }
    .ant-tabs-nav-list {
      width: 100%;

      .ant-tabs-tab {
        display: inline-flex;
        justify-content: center;
        flex-grow: 1;
        flex-shrink: 0;
        margin-left: 0;
        padding: 0.24rem 0.1rem;

        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            font-weight: 500;
            color: #2d69d8;
          }
        }
        .ant-tabs-tab-btn {
          font-size: 0.28rem;
          font-weight: 300;
          color: #383838;
        }
      }
    }
    .ant-tabs-ink-bar {
      height: 0.05rem !important;
      border-radius: 2px;
      background: #2d69d8;
    }
  }
}

@primary-color: #249BF9;@border-radius-base: 4px;@text-color: #515151;