.typicalDetail {
  padding: 20px;

  .bread {
    margin-bottom: 20px;

    :global {
      .ant-breadcrumb a {
        color: #858585;
      }
      .ant-breadcrumb-separator {
        color: #858585;
      }
      .ant-breadcrumb>span:last-child a {
        color: #2D69D8;
      }
    }
  }

  .mainCont {
    background-color: #fff;
    padding: 40px;

    .mainBox {
      width: 800px;
      margin: auto;

      .mianTitle {
        font-size: 26px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #070707;
        line-height: 45px;
        margin-bottom: 12px;
        text-align: center;
      }

      .time {
        font-size: 16px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #858585;
        line-height: 22px;
        margin-bottom: 20px;
        text-align: center;
      }

      .line {
        width: 100%;
        border-bottom: 1px solid #D8D8D8;
        margin-bottom: 19px;
      }

      .tagBox {
        padding: 20px 0;
        padding-bottom: 8px;
        text-align-last: left;

        .tag {
          height: 32px;
          line-height: 32px;
          border-radius: 16px;
          padding: 0px 12px;
          font-size: 14px;
          margin-right: 20px;
          display: inline-block;
          max-width: 180px;
          margin-bottom: 12px;
        }

        .blueTag {
          background: rgba(45, 105, 216, 0.1);
          color: #2D69D8;
        }

        .orangeTag {
          background: #FEF8F3;
          color: #FF7212;
        }
      }

      .nextArticl {
        text-align: right;
        cursor: pointer;
        &:hover {
          color: #2D69D8;
        }
      }
    }
  }
}
@primary-color: #249BF9;@border-radius-base: 4px;@text-color: #515151;